import React                                         from 'react';
import Img                                           from 'gatsby-image';
import { graphql, useStaticQuery }                   from 'gatsby';
import { Center, Flex, Box, useMediaQuery, Heading } from '@chakra-ui/react';

import Spacer from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Link   from '@interness/web-core/src/components/elements/Link/Link';

import RolexLayout   from '../../../components/Layout';
import KeepExploring from '../../../components/KeepExploring';
import Tracking      from '../../../components/Tracking';

const RolexKollektionPage = () => {
  const data = useStaticQuery(query);
  const [portrait] = useMediaQuery('(max-width: 768px)');
  const name = data.directusCompany.display_name;

  const media = {
    cover: {
      portrait: data.coverPortrait.childImageSharp.fluid,
      landscape: data.coverLandscape.childImageSharp.fluid,
    },
    banner: {
      portrait: data.bannerPortrait.childImageSharp.fluid,
      landscape: data.bannerLandscape.childImageSharp.fluid,
    },
    cosmographDaytona: {
      portrait: data.cosmographDaytonaPortrait.childImageSharp.fluid,
      landscape: data.cosmographDaytonaLandscape.childImageSharp.fluid,
    },
    dayDate: {
      portrait: data.dayDatePortrait.childImageSharp.fluid,
      landscape: data.dayDateLandscape.childImageSharp.fluid,
    },
    explorer: {
      portrait: data.explorerPortrait.childImageSharp.fluid,
      landscape: data.explorerLandscape.childImageSharp.fluid,
    },
    gmtMaster: {
      portrait: data.gmtMasterPortrait.childImageSharp.fluid,
      landscape: data.gmtMasterLandscape.childImageSharp.fluid,
    },
    oysterPerpetual: {
      portrait: data.oysterPerpetualPortrait.childImageSharp.fluid,
      landscape: data.oysterPerpetualLandscape.childImageSharp.fluid,
    },
    perpetual: {
      portrait: data.perpetualPortrait.childImageSharp.fluid,
      landscape: data.perpetualLandscape.childImageSharp.fluid,
    },
    skyDweller: {
      portrait: data.skyDwellerPortrait.childImageSharp.fluid,
      landscape: data.skyDwellerLandscape.childImageSharp.fluid,
    },
    yachtMaster: {
      portrait: data.yachtMasterPortrait.childImageSharp.fluid,
      landscape: data.yachtMasterLandscape.childImageSharp.fluid,
    },
  }

  return (
    <RolexLayout>
      <Tracking pageType={'new watches page'}/>
      <section>
        <Img width="100%" height="25vh"
             fluid={portrait ? media.cover.portrait : media.cover.landscape}/>
      </section>
      <Spacer/>
      <section>
        <Center width="90%" margin="auto">
          <Box maxW="650px" w="100%" textAlign="center">
            <p><b>Rolex Armbanduhren</b></p>
            <Box sx={{
              letterSpacing: '4.5px',
              fontWeight: '300',
              lineHeight: '1.2',
            }}>
              <Heading as="h1" size="lg" sx={{ hyphens: 'auto' }}>NEUE ROLEX MODELLE 2023 – {name.toUpperCase()}</Heading>
            </Box>
            <p><b>Mit den neuesten Kreationen stellt Rolex wieder sein unablässiges Streben nach Exzellenz unter
              Beweis.</b></p>
            <p>Eine ständige Herausforderung, die in der Perfektionierung jedes Elements, dem Hinterfragen jeder
              Errungenschaft und der Meisterschaft in jedem Know-how zum Ausdruck kommt. Die vorgestellten Modelle
              zeugen von dem Willen, sich in der Kontinuität neu zu erfinden, im Geiste der Tradition innovativ zu sein
              und die Technik in den Dienst kreativen Wagemuts zu stellen. Solcherlei Anstrengungen – geprägt von einem
              Anspruch der Superlative, der sich in jeder Phase der Realisierung bis ins kleinste Detail manifestiert –
              sind die Grundlage für die Entwicklung und den Fortbestand uhrmacherischer Referenzen. Die im Laufe der
              Zeit zu Ikonen werden.</p>
          </Box>
        </Center>
      </section>
      <Spacer/>
      <section>
        <Box maxW={'1140px'} w={portrait ? '90%' : '80%'} margin="auto" fontWeight="bold"
             sx={{ a: { color: 'black' } }}>
          <Flex flexWrap="wrap" alignItems="center" justifyContent="space-between">
            <Box width={'49%'}>
              <Link to="/uhren/rolex/kollektion?cmplp=rolex-watches/cosmograph-daytona/m126506-0002">
                <Img width="100%"
                     fluid={portrait ? media.cosmographDaytona.portrait : media.cosmographDaytona.landscape}/>
                <p>COSMOGRAPH DAYTONA</p>
              </Link>
            </Box>
            <Box width={'49%'}>
              <Link to="/uhren/rolex/kollektion?cmplp=rolex-watches/1908/m52508-0006">
                <Img width="100%" fluid={portrait ? media.perpetual.portrait : media.perpetual.landscape}/>
                <p>1908</p>
              </Link>
            </Box>
            <Box width={'49%'}>
              <Link to="/uhren/rolex/kollektion?cmplp=rolex-watches/yacht-master/m226627-0001">
                <Img width="100%" fluid={portrait ? media.yachtMaster.portrait : media.yachtMaster.landscape}/>
                <p>YACHT-MASTER 42</p>
              </Link>
            </Box>
            <Box width={'49%'}>
              <Link to="/uhren/rolex/kollektion?cmplp=rolex-watches/sky-dweller/m336239-0002">
                <Img width="100%" fluid={portrait ? media.skyDweller.portrait : media.skyDweller.landscape}/>
                <p>SKY-DWELLER</p>
              </Link>
            </Box>
            <Box width={'49%'}>
              <Link to="/uhren/rolex/kollektion?cmplp=rolex-watches/gmt-master-ii/m126713grnr-0001">
                <Img width="100%" fluid={portrait ? media.gmtMaster.portrait : media.gmtMaster.landscape}/>
                <p>GMT-MASTER II</p>
              </Link>
            </Box>
            <Box width={'49%'}>
              <Link to="/uhren/rolex/kollektion?cmplp=rolex-watches/explorer/m224270-0001">
                <Img width="100%" fluid={portrait ? media.explorer.portrait : media.explorer.landscape}/>
                <p>EXPLORER 40</p>
              </Link>
            </Box>
            <Box width={'49%'}>
              <Link to="/uhren/rolex/kollektion?cmplp=rolex-watches/oyster-perpetual/m124300-0008">
                <Img width="100%" fluid={portrait ? media.oysterPerpetual.portrait : media.oysterPerpetual.landscape}/>
                <p>OYSTER PERPETUAL</p>
              </Link>
            </Box>
            <Box width={'49%'}>
              <Link to="/uhren/rolex/kollektion?cmplp=rolex-watches/day-date/m128345rbr-0068">
                <Img width="100%" fluid={portrait ? media.dayDate.portrait : media.dayDate.landscape}/>
                <p>DAY-DATE 36</p>
              </Link>
            </Box>
          </Flex>
        </Box>
      </section>
      <Spacer/>
      <section>
        <Img width="100%" fluid={portrait ? media.banner.portrait : media.banner.landscape}/>
      </section>
      <Spacer/>
      <KeepExploring/>
      <Spacer/>
    </RolexLayout>
  )
};

export default RolexKollektionPage;

const query = graphql`
    query {
        directusCompany {
            display_name
        }
        coverLandscape: file(name: {eq: "new_2023_watches_cover"} relativeDirectory: {eq: "landscape/new-watches"}) {
            name
            childImageSharp {
                fluid(quality: 100, maxWidth: 2880) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        coverPortrait: file(name: {eq: "new_2023_watches_cover_portrait"} relativeDirectory: {eq: "portrait/new-watches"}) {
            name
            childImageSharp {
                fluid(quality: 100, maxWidth: 750) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bannerLandscape: file(name: {eq: "new_2023_banner"} relativeDirectory: {eq: "landscape/new-watches"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        bannerPortrait: file(name: {eq: "new_2023_banner_portrait"} relativeDirectory: {eq: "portrait/new-watches"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        cosmographDaytonaLandscape: file(name: {eq: "new_2023_watches_cosmograph_daytona"} relativeDirectory: {eq: "landscape/new-watches"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        cosmographDaytonaPortrait: file(name: {eq: "new_2023_watches_cosmograph_daytona_portrait"} relativeDirectory: {eq: "portrait/new-watches"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        dayDateLandscape: file(name: {eq: "new_2023_watches_day_date_36"} relativeDirectory: {eq: "landscape/new-watches"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        dayDatePortrait: file(name: {eq: "new_2023_watches_day_date_36_portrait"} relativeDirectory: {eq: "portrait/new-watches"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        explorerLandscape: file(name: {eq: "new_2023_watches_explorer"} relativeDirectory: {eq: "landscape/new-watches"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        explorerPortrait: file(name: {eq: "new_2023_watches_explorer_portrait"} relativeDirectory: {eq: "portrait/new-watches"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        gmtMasterLandscape: file(name: {eq: "new_2023_watches_gmt_master_2"} relativeDirectory: {eq: "landscape/new-watches"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        gmtMasterPortrait: file(name: {eq: "new_2023_watches_gmt_master_2_portrait"} relativeDirectory: {eq: "portrait/new-watches"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        oysterPerpetualLandscape: file(name: {eq: "new_2023_watches_oyster_perpetual"} relativeDirectory: {eq: "landscape/new-watches"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        oysterPerpetualPortrait: file(name: {eq: "new_2023_watches_oyster_perpetual_portrait"} relativeDirectory: {eq: "portrait/new-watches"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        skyDwellerLandscape: file(name: {eq: "new_2023_watches_sky_dweller"} relativeDirectory: {eq: "landscape/new-watches"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        skyDwellerPortrait: file(name: {eq: "new_2023_watches_sky_dweller_portrait"} relativeDirectory: {eq: "portrait/new-watches"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        yachtMasterLandscape: file(name: {eq: "new_2023_watches_yacht_master_42"} relativeDirectory: {eq: "landscape/new-watches"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        yachtMasterPortrait: file(name: {eq: "new_2023_watches_yacht_master_42_portrait"} relativeDirectory: {eq: "portrait/new-watches"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        perpetualLandscape: file(name: {eq: "new_2023_watches_perpetual_1908"} relativeDirectory: {eq: "landscape/new-watches"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        perpetualPortrait: file(name: {eq: "new_2023_watches_perpetual_1908_portrait"} relativeDirectory: {eq: "portrait/new-watches"}) {
            name
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;