import React, { useContext, useEffect, useState } from 'react';
import useScript                                  from 'react-script-hook';

import { GlobalContext } from '@interness/web-core/src/providers';

const Tracking = ({ pageType }) => {
  let isMounted = false;
  const [rolexConsent, setRolexConsent] = useState(false);
  const { userConsents } = useContext(GlobalContext);
  const env = process.env.NODE_ENV;

  useEffect(() => {
    isMounted = true;
    setRolexConsent(userConsents.find(consent => consent.function_id === 'rolex').consentGiven);
    //eslint-disable-next-line
  }, [isMounted, userConsents]);


  const scripts = {
    adobe: {
      development: '//assets.adobedtm.com/7e3b3fa0902e/7ba12da1470f/launch-73c56043319a-staging.min.js',
      production: '//assets.adobedtm.com/7e3b3fa0902e/7ba12da1470f/launch-5de25e657d80.min.js'
    }
  }

  const [loadingAdobe] = useScript({
    src: rolexConsent ? scripts.adobe[env] : null,
    checkForExisting: true
  });

  useEffect(() => {
    if (rolexConsent && !loadingAdobe) {
      window.coBrandVersion = 'Hybrid';
      window.digitalDataLayer = {
        page: {
          pageType: pageType
        },
      }
      if (window._satellite) {
        console.log('Start Rolex Tracking');
        window._satellite.pageBottom();
      }
    }
  }, [rolexConsent, loadingAdobe]);

  return null;

};

export default Tracking;